<template>
    <b-card>
        <div>
            <!-- search input -->
            <div class="custom-search d-flex">
                <div class="d-flex mr-auto">
                    <div style="float: left">
                        <b-dropdown
                            id="dropdown-7"
                            v-ripple.400="'rgba(255, 255, 255, 0)'"
                            text="Actions"
                            variant="secondary"
                        >
                            <b-dropdown-item>Select action:</b-dropdown-item>
                            <b-dropdown-item
                                :disabled="!activate"
                                @click="save(true)"
                            >
                                <feather-icon
                                    class="text-success mr-right-10"
                                    icon="CheckIcon"
                                />Activate
                            </b-dropdown-item>
                            <b-dropdown-item
                                :disabled="!deactivate"
                                @click="save(false)"
                            >
                                <feather-icon
                                    class="text-danger mr-right-10"
                                    icon="XIcon"
                                />Deactivate
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <b-form-group>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </b-form-group>
            </div>
            <!-- table -->
            <vue-good-table
                v-if="rows.length > 0"
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                }"
                :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }"
                :pagination-options="{
                    enabled: true,
                    perPage: 25,
                }"
                @on-selected-rows-change="selectionChanged"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'is_active'">
                        <b-badge
                            v-if="props.row.is_active === 'true'"
                            :variant="statusVariant(props.row.is_active)"
                        >
                            Active
                        </b-badge>
                        <b-badge
                            v-else
                            :variant="statusVariant(props.row.is_active)"
                        >
                            Inactive
                        </b-badge>
                    </span>
                    <span v-else-if="props.column.field === 'groups'">
                        <span
                            v-for="(item, index) in props.row.groups"
                            :key="item.id"
                        >
                            {{ item.name
                            }}<span
                                v-if="
                                    props.row.groups.length > 1 &&
                                    index < props.row.groups.length - 1
                                "
                                >,</span
                            >
                        </span>
                    </span>
                    <span
                        v-else-if="props.column.field === 'full_name'"
                        v-b-tooltip.hover.left
                        title="Click to edit user permission!"
                        style="cursor: pointer"
                        @click="
                            $router.push(
                                '/settings/permissions/' + props.row.id
                            )
                        "
                    >
                        <b-avatar
                            class="mr-right-10"
                            size="36px"
                            :src="props.row.employee.avatar"
                        />
                        {{ props.row.full_name }}
                    </span>
                    <span v-else-if="props.column.field === 'is_staff'">
                        <span v-if="props.row.is_staff === 'true'">
                            <b-badge variant="light-success"> Yes </b-badge>
                        </span>
                        <span v-else>
                            <b-badge variant="light-danger"> No </b-badge>
                        </span>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <!-- <div class="mt-43" v-if="selected.length > 0">
              Selected: {{ selected.length }}
            </div>
            <div class="mt-43" v-else>
              Total: {{ rows.length }}
            </div> -->
                        <div>
                            <b-pagination
                                :value="currentPage"
                                :total-rows="total"
                                :per-page="25"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="fetchRows"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </b-card>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BCard,
    VBTooltip,
    VBModal,
    BAlert,
    BModal,
    BCardText,
} from "bootstrap-vue"
import vSelect from "vue-select"
import { VueGoodTable } from "vue-good-table"
import store from "@/store/index"
import Auth from "@/api/services/Auth"
import Ripple from "vue-ripple-directive"
import { MonthPicker } from "vue-month-picker"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

export default {
    name: "Permissions",
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BButton,
        BCard,
        MonthPicker,
        VBModal,
        BAlert,
        BModal,
        ToastificationContent,
        vSelect,
        BCardText,
        DatePicker,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
        "b-modal": VBModal,
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            activate: false,
            deactivate: false,
            pageLength: 25,
            dir: false,
            columns: [
                {
                    label: "Status",
                    field: "is_active",
                    tdClass: "height-50",
                },
                {
                    label: "User",
                    field: "full_name",
                    tdClass: "width-user",
                },
                {
                    label: "Department",
                    field: "employee.department",
                },
                {
                    label: "Email",
                    field: "email",
                },
                {
                    label: "Permission",
                    field: "groups",
                },
                {
                    label: "Staff status",
                    field: "is_staff",
                    tdClass: "width-staff",
                },
            ],
            rows: [],
            searchTerm: "",
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.loadData()
    },
    watch: {
        searchTerm(newSearchTerm) {
            this.fetchRows(1); // Reinicia para a primeira página ao fazer uma nova busca
        }
    },
    methods: {
        fetchRows(page = 1) {
            const params = {
                page: page,
                page_size: 25,
                search: this.searchTerm,
            };
            Auth.getUserPermission("", params)
                .then((response) => {
                    this.rows = response.data.results;
                    this.total = response.data.count;
                    this.currentPage = page;
                })
                .catch((error) => {
                    console.error("Erro ao buscar os dados:", error);
                });
        },
        save(value) {
            this.$loading(true)
            this.selected.forEach((element) => {
                Auth.putUserPermission({
                    id: element.id,
                    is_active: value,
                }).then(() => {
                    this.loadData()
                    switch (value) {
                        case true:
                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    props: {
                                        title: "Success",
                                        icon: "CheckCircleIcon",
                                        text: "User has been successfully activated! 🥳",
                                        variant: "success",
                                    },
                                },
                                {
                                    position: "top-center",
                                }
                            )
                            break
                        case false:
                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    props: {
                                        title: "Success",
                                        icon: "CheckCircleIcon",
                                        text: "User has been successfully deactivated! 🥳",
                                        variant: "success",
                                    },
                                },
                                {
                                    position: "top-center",
                                }
                            )
                            break
                        default:
                            break
                    }
                })
            })
        },
        selectionChanged(event) {
            this.selected = event.selectedRows
            let activate = true
            let deactivate = true
            if (this.selected.length === 0) {
                activate = false
                deactivate = false
            }
            this.selected.forEach((item) => {
                if (item.is_active === true) {
                    activate = false
                }
                if (item.is_active === false) {
                    deactivate = false
                }
            })
            this.activate = activate
            this.deactivate = deactivate
        },
        statusVariant(status) {
            // Remova a comparação com "true" string
            switch (status) {
                case true:
                    return "light-success"
                case false:
                    return "light-danger"
                default:
                    return "light-primary"
            }
        },
        async loadData() {
            this.rows = []
            this.$loading(true)
            try {
                const response = await Auth.getUserPermission()
                this.rows = response?.data?.results?.map((e) => {
                    e.groups = e.groups.length > 0 ? e.groups : ""
                    // e.is_active = e?.is_active.toString()
                    // e.is_staff = e?.is_staff.toString()
                    return e
                })
            } catch (error) {
                console.log(error)
            } finally {
                this.$loading(false)
            }
        },
    },
    mounted() {
        this.fetchRows(this.currentPage);
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
